$primary: #2bd17e;
$error: #eb5757;
$bg_color: #093545;
$input_color: #224957;
$card_color: #092c39;
$grey_100: #c3c8d4;
$white: #ffffff;

$breakpoint1: 400px;
$breakpoint2: 992px;
$breakpoint3: 1140px;
$breakpoint4: 1340px;
$breakpoint5: 1440px;
$breakpoint6: 500px;
