@import "./variable";

@mixin greenButton {
  display: block;
  padding: 15px 26px;
  width: 100%;
  height: 100%;
  min-height: 54px;
  border-radius: 10px;
  background: $primary;
  color: $white;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}

@mixin transparantBtn {
  display: block;
  padding: 15px 26px;
  width: 100%;
  height: 100%;
  min-height: 54px;
  border-radius: 10px;
  background: transparent;
  color: $white;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  border: 1px solid #fff;
}
