@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-family: "Montserrat", sans-serif;
}

body {
  background-color: #093545;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #092c39;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2bd17e;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2bd17e;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}

img {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
}

.inputWrapper {
  width: 100%;
  margin-bottom: 24px;
}
.inputWrapper input {
  padding: 10px 16px 11px;
  width: 100%;
  height: 100%;
  min-height: 45px;
  border-radius: 10px;
  color: #ffffff;
  background: #224957;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.inputWrapper input::-moz-placeholder {
  color: #ffffff;
}
.inputWrapper input::placeholder {
  color: #ffffff;
}
.inputWrapper input.small_input {
  max-width: 216px;
}
.inputWrapper .error {
  color: #eb5757;
  margin-top: 5px;
}

.commonBtn {
  display: block;
  padding: 15px 26px;
  width: 100%;
  height: 100%;
  min-height: 54px;
  border-radius: 10px;
  background: transparent;
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  border: 1px solid #fff;
}

.submitBtn {
  display: block;
  padding: 15px 26px;
  width: 100%;
  height: 100%;
  min-height: 54px;
  border-radius: 10px;
  background: #2bd17e;
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}

.loader {
  position: relative;
}
.loader::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-radius: 100%;
  bottom: 0;
  margin: auto;
  animation: animateSpin 1s linear infinite;
}
@media only screen and (max-width: 400px) {
  .loader::before {
    width: 14px;
    height: 14px;
  }
}

@keyframes animateSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader_Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 60px;
  width: 100vw;
  height: 100vh;
}
.loader_Wrapper .loading_loader {
  color: #fff;
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%, 100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}/*# sourceMappingURL=App.css.map */